import {PageMap} from '@wix/wixstores-client-core';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {ControllerParams} from '@wix/yoshi-flow-editor';
import {BIService} from '../services/BIService';
import {CartService} from './CartService';
import {ContinueShoppingBiOrigin} from '../../components/cart/constants';
import {ICartControllerApi} from '../../types/app.types';
import {ModalManagerService} from './ModalManagerService';

export class NavigationService {
  private readonly controllerApi: ICartControllerApi;
  public get isNavigationToCheckoutInProcess(): boolean {
    return this._isNavigationToCheckoutInProcess;
  }

  public set isNavigationToCheckoutInProcess(value: boolean) {
    this._isNavigationToCheckoutInProcess = value;
    //eslint-disable-next-line @typescript-eslint/no-floating-promises
    this.controllerApi.updateComponent();
  }
  private readonly siteStore: SiteStore;
  private readonly biService: BIService;
  private readonly publicData: ControllerParams['controllerConfig']['config']['publicData'];
  private readonly cartService: CartService;
  public readonly modalManagerService: ModalManagerService;
  private _isNavigationToCheckoutInProcess: boolean = false;

  constructor({
    siteStore,
    biService,
    cartService,
    publicData,
    controllerApi,
    modalManagerService,
  }: {
    siteStore: SiteStore;
    biService: BIService;
    cartService: CartService;
    modalManagerService: ModalManagerService;
    publicData: ControllerParams['controllerConfig']['config']['publicData'];
    controllerApi: ICartControllerApi;
  }) {
    this.siteStore = siteStore;
    this.cartService = cartService;
    this.biService = biService;
    this.publicData = publicData;
    this.controllerApi = controllerApi;
    this.modalManagerService = modalManagerService;
  }

  private get continueShoppingLinkObject() {
    return this.publicData.COMPONENT.CART_CONTINUE_SHOPPING_LINK_OBJECT;
  }

  public readonly continueShopping = async (origin = ContinueShoppingBiOrigin.shoppingCart) => {
    this.biService.cartClickOnContinueShoppingSf(this.cartService.cart, origin);
    this.siteStore.navigateToLink(await this.getLink());
  };

  private getLink() {
    return this.continueShoppingLinkObject
      ? this.siteStore.getLink(this.continueShoppingLinkObject)
      : this.siteStore.getHomepageLink();
  }

  /* istanbul ignore next */
  public async getContinueShoppingHref() {
    try {
      return (await this.getLink()).url;
    } catch {
      //
    }
    return '#';
  }

  public readonly navigateToProduct = (urlPart: string) => {
    const to = {sectionId: PageMap.PRODUCT, state: urlPart};
    return this.siteStore.navigate(to, true);
  };
}
